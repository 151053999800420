<template>
	<div class="AuditDemotion">
		<w-navTab titleText="义警降级"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="AuditDemotion-content">
			<div class="header">
				<div class="header-top-item" @click="districtShow = true" v-if="dutyLevel >= 4">
					{{districtText}}<van-icon name="play" style="transform: rotate(90deg); margin-left: 2px;" />
				</div>
				<div class="header-top-item" v-else>
					{{districtText}}
				</div>
				<div class="header-top-item" @click="departmentShow = true">
					{{departmentText}}<van-icon name="play" style="transform: rotate(90deg); margin-left: 2px;" />
				</div>
			</div>
			<div class="body">
				<div class="body-nav">
					<div :class="['body-nav-item', {'item-active': navIndex == index}]" v-for="(item,index) in navList"
						@click="onClickNav(index)">
						{{item.title}}
					</div>
				</div>
				<div class="body-nav-title">
					（<span>说明：</span>{{navIndex == 1? '365天还未达到150小时':'剩余3个月还未达到150小时'}}）
				</div>
				<!-- <div class="body-list-total">总人数：288</div> -->
				<VantList class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
					<div class="body-content" v-for="(item,index) in AuditList">
						<div class="content-top">
							<div class="top-left">
								<div class="top-left-icon">
									<img :src="item.avatar" alt="">
								</div>
								<div class="top-left-content">
									<div class="left-content-name">{{item.name}}</div>
									<div class="left-content-text">注册时间：{{item.review_time}}</div>
								</div>
							</div>
							<div class="top-right"
								@click="$router.push({ name: 'AuditUserInfo', params:{id: item.user_id}})">
								个人信息<van-icon style="margin-left: 2px;" name="arrow" />
							</div>
						</div>
						<div class="content-middle">
							<div class="middle-content">
								<div class="middle-content-lable">所属队伍：</div>
								<div class="middle-content-text">{{item.department_name}}</div>
							</div>
							<div class="middle-content">
								<div class="middle-content-lable">近9个月活动时长：</div>
								<div class="middle-content-text">{{getMath(item.service_total)}}小时</div>
							</div>
							<div class="middle-content">
								<div class="middle-content-lable">上次登录时间：</div>
								<div class="middle-content-text" v-if="item.last_login_time">{{formatStartDate(item.last_login_time)}}（{{formatDate(item.last_login_time)}}）</div>
							</div>
						</div>
						<div class="content-footer">
							<div class="content-footer-text">手机号：<span>{{item.telephone}}</span></div>
							<div class="content-footer-botton" @click="callPhone(item.telephone)">
								<img src="@/assets/img/u_icon116.png" alt="">
							</div>
						</div>
					</div>
				</VantList>
			</div>
		</div>

		<van-popup v-model="districtShow" position="bottom" :style="{ height: '40%' }">
			<van-picker title="标题" show-toolbar value-key="name" :default-index="districtIndex" :columns="districtList"
				@confirm="onConfirmDistrict" @cancel="districtShow = !districtShow" />
		</van-popup>

		<van-popup v-model="departmentShow" position="bottom" :style="{ height: '40%' }">
			<van-picker title="标题" show-toolbar value-key="title" :default-index="departmentIndex"
				:columns="departmentList" @confirm="onConfirmDepartment" @cancel="departmentShow = !departmentShow" />
		</van-popup>
	</div>
</template>

<script>
	import User from '@/api/user';
	import Group from '@/api/group';
	import VantList from '@/components/VantList';
	import VantVendor from '@/vendor/vant';
	import LoadingPage from '@/components/LoadingPage';
	import Moment from '@/vendor/moment';
	
	export default {
		name: 'AuditDemotion',
		data() {
			return {
				loadingPage: true,
				navList: [{
					id: 1,
					title: '需要督促人员'
				}, {
					id: 2,
					title: '未达标人员'
				}],
				navIndex: 0,
				loading: true,
				finished: false,
				isEmpty: false,
				paramsPage: 1,
				paramsLimit: 10,
				type: '',
				AuditList: [],

				districtId: '',
				districtText: '',
				districtShow: false,
				districtList: [],
				districtIndex: 0,

				departmentText: '',
				departmentId: '',
				departmentShow: false,
				departmentList: [],
				departmentIndex: 0,
				dutyLevel: ''
			}
		},
		created() {
			this.$store.commit('keep/setKeep', 'AuditDemotion');
			this.dutyLevel = this.$route.params.dutyLevel
			this.districtId = this.$route.params.id
			this.getDistrictList()
			this.onLoad()
		},
		methods: {
			formatDate(value) {
				return Moment(value).startOf('seconds').fromNow();
			},
			formatStartDate(item) {
				return Moment(item).format('YYYY-MM-DD')
			},
			getMath(min) {
				let time = min / 60
				return time.toFixed(2);
			},
			// 大队
			getDepartmentList() {
				let params = {
					district_id: this.districtId
				}

				User.department(params)
					.then(result => {
						this.departmentList = result.data;

						let item = {
							id: 0,
							title: '全部队伍'
						}
						this.departmentList.unshift(item)
						this.departmentId = this.departmentList[0].id
						this.departmentText = this.departmentList[0].title

					})
					.catch(error => {
						console.log(error);
					});
			},
			// 区县
			getDistrictList() {
				User.county()
					.then(result => {
						this.districtList = result.data
						let index = this.districtList.findIndex(item =>item.id == this.districtId)
						this.districtIndex = index
						
						this.districtText = this.districtList[index].name
						this.getDepartmentList()
					})
					.catch(error => {
						console.log(error);
					});
			},
			callPhone(phone) {
				window.location.href = 'tel://' + phone;
			},
			onLoad() {
				this.loading = true;

				let params = {
					type: this.navList[this.navIndex].id,
					district_id: this.districtId,
					department_id: this.departmentId,
					page: this.paramsPage,
					limit: this.paramsLimit
				}

				User.demotion(params)
					.then(result => {
						this.paramsPage++;
						this.AuditList = this.AuditList.concat(result.data);
						this.loadingPage = false
						this.loading = false;
						
						if (this.AuditList.length <= 0) {
							this.isEmpty = true;
						}
						
						if (result.data.length < this.paramsLimit) {
							this.finished = true;
						}
						
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					});
			},
			onClickNav(index) {
				this.navIndex = index
				this.paramsPage = 1
				this.AuditList = []
				this.finished = false
				this.isEmpty = false
				this.onLoad()
			},
			onConfirmDistrict(e, index) {
				this.districtShow = false
				this.districtId = e.id
				this.districtText = e.name
				this.districtIndex = index
				this.paramsPage = 1
				this.AuditList = []
				this.finished = false
				this.isEmpty = false
				this.departmentList = []
				this.departmentIndex = 0
				this.departmentId = 0
				this.getDepartmentList()
				this.onLoad()
			},
			onConfirmDepartment(e, index) {
				this.departmentShow = false
				this.departmentId = e.id
				this.departmentText = e.title
				this.departmentIndex = index
				this.paramsPage = 1
				this.AuditList = []
				this.finished = false
				this.isEmpty = false
				this.onLoad()
			}
		},
		components: {
			VantList,
			VantVendor,
			LoadingPage
		}
	}
</script>

<style scoped lang="less">
	.AuditDemotion {
		.AuditDemotion-content {
			.header {
				padding: 7px 0;
				display: flex;
				justify-content: center;
				align-items: center;
				box-sizing: border-box;
				background: #FFF;

				.header-top-item {
					margin-right: 47px;
					color: #333;
					font-size: 15px;
					line-height: 28px;


					&.header-top-item:last-child {
						margin-right: 0;
					}
				}
			}

			.body {
				padding: 0 16px 16px;
				box-sizing: border-box;

				.body-nav {
					margin: 12px 0 4px;
					display: flex;
					justify-content: center;
					align-items: center;

					.body-nav-item {
						padding: 0 6px;
						color: #999;
						font-size: 14px;
						line-height: 28px;
						border-radius: 2px;
						background: #FFF;
						text-align: center;
						border: 1px solid #fff;
						box-sizing: border-box;
						margin-right: 12px;

						&.body-nav-item:last-child {
							margin-right: 0;
						}

						&.item-active {
							color: #37F;
							border: 1px solid #37F;
						}
					}
				}
				
				.body-nav-title{
					color: #999;
					text-align: center;
					font-size: 12px;
					line-height: 28px;
					margin-bottom: 8px;
					
					span{
						color: #F3B100;
					}
				}
				
				.body-list-total{
					color: #333;
					font-size: 14px;
					line-height: 21px;
					margin-bottom: 12px;
				}
				
				.body-content {
					border-radius: 8px;
					background: #FFF;
					padding: 0 12px;
					box-sizing: border-box;
					margin-bottom: 12px;

					&.body-content:last-child {
						margin-bottom: 0;
					}

					.content-top {
						padding: 15px 0;
						display: flex;
						justify-content: space-between;
						align-items: center;
						border-bottom: 1px solid #F2F2F2;
						box-sizing: border-box;

						.top-left {
							display: flex;
							align-items: center;

							.top-left-icon {
								width: 40px;
								height: 40px;
								min-width: 40px;
								margin-right: 8px;

								img {
									width: 100%;
									height: 100%;
									border-radius: 50%;
									object-fit: cover;
								}
							}

							.top-left-content {
								.left-content-name {
									color: #333;
									font-size: 17px;
									line-height: 26px;
								}

								.left-content-text {
									color: #999;
									font-size: 13px;
									line-height: 20px;
								}
							}
						}

						.top-right {
							color: #999;
							font-size: 13px;
							line-height: 26px;
						}
					}

					.content-middle {
						padding: 12px 0;
						border-bottom: 1px solid #F2F2F2;
						box-sizing: border-box;

						.middle-content {
							display: flex;
							align-items: center;
							margin-top: 8px;

							&.middle-content:first-child {
								margin-top: 0;
							}

							.middle-content-lable {
								color: #999;
								font-size: 15px;
								line-height: 23px;
								flex-wrap: nowrap;
							}

							.middle-content-text {
								flex: 1;
								color: #333;
								font-size: 15px;
								line-height: 23px;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
							}
						}
					}

					.content-footer {
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding: 10px 0;
						box-sizing: border-box;

						.content-footer-text {
							color: #999;
							font-size: 15px;
							line-height: 23px;

							span {
								color: #37F;
							}
						}

						.content-footer-botton {
							width: 28px;
							height: 28px;
							min-width: 28px;
							margin-right: 28px;

							img {
								width: 100%;
								height: 100%;
								border-radius: 50%;
								object-fit: cover;
							}
						}
					}
				}
			}
		}
	}
</style>